var render = function () {
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "card card-congratulations",
    attrs: {
      "text-variant": "left"
    }
  }, [_c('b-img', {
    staticClass: "congratulations-img-right",
    attrs: {
      "src": require('@/assets/images/elements/decore-right.png')
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "mb-2 mt-50 text-black"
  }, [_vm._v(" Welcome " + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + ", ")]), _c('b-card-text', {
    staticClass: "w-75 mx-auto text-black lead custom-black-color"
  }, [_vm._v(" Easily set up your software APIs right now! Follow the "), _c('span', {
    staticClass: "font-weight-1000"
  }, [_c('router-link', {
    staticClass: "setup-color-black",
    attrs: {
      "to": {
        path: 'user/api'
      }
    }
  }, [_vm._v("Set Up Guide")])], 1), _vm._v(" for API installation tutorials and start searching for leads! ")]), _c('div', {
    staticClass: "d-flex justify-content-center mt-3"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "dark",
      "to": {
        path: 'user/api'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Setup Guide")])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "CpuIcon",
      "statistic": _vm.totalLeads,
      "statistic-title": "Total Leads"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "ServerIcon",
      "color": "success",
      "statistic": _vm.totalCampaigns,
      "statistic-title": "Total Campaigns"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "AlertOctagonIcon",
      "color": "warning",
      "statistic": _vm.leadsContacted,
      "statistic-title": "Leads Contacted"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "md": "12",
      "lg": "7",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('h4', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("Recent Leads")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "variant": "secondary",
      "to": "/api/campaign-leads/all"
    }
  }, [_vm._v(" View All ")])], 1)], 1), _c('b-card-text', {
    staticClass: "w-100 text-center"
  }, [_c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.recentLeads
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "5",
      "sm": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('h4', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("Recent Campaigns")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "variant": "secondary",
      "to": "/admin/campaigns"
    }
  }, [_vm._v(" View All ")])], 1)], 1), _c('b-card-text', {
    staticClass: "w-100 text-center"
  }, [_c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.recentCampaigns,
      "fields": _vm.fields
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }